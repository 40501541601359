import { useContext } from 'react';
import { QueryClient, useQuery } from 'react-query';
import dynamic from 'next/dynamic';
import type { NextPage } from 'next';
import Head from 'next/head';
import cn from 'classnames';
import { articles, banner } from 'api';
import { LanguageContext } from 'context/Language';
import { StoreContext } from 'context/Store';
import { useTranslation } from 'hooks';
import withStore from 'context/Store/withStore';
import { getSeoTitle, cacheTime, getQueries, defaultOptions, defaultFilters } from 'utils';
import { Article, ArticleTags, Banner, BannerType, Results } from 'types';
import { NextArrow, PrevArrow, RowLeft, RowRight } from 'resources';

const Container = dynamic(() => import('components/Grid/Container'));
const Icon = dynamic(() => import('components/Icon'));
const Button = dynamic(() => import('components/Button'));
const Carousel = dynamic(() => import('components/Carousel'));
const ItemBanner = dynamic(() => import('components/Carousel/ItemBanner'));
const ItemComponents = dynamic(() => import('components/Carousel/ItemComponents'));
const Section = dynamic(() => import('components/Layout/Section'));
const SliderBox = dynamic(() => import('components/SliderBox'));
const Responsive = dynamic(() => import('components/Responsive'));
const NavigationSettings = dynamic(() => import('components/NavigationSettings'));
const Wrapper = dynamic(() => import('components/Wrapper'));

interface HomeProps {
  headBanners: Results<Banner>;
  adBanners: Results<Banner>;
  articleList: Results<Article>;
  specialBanners: Results<Banner>;
}

const Home: NextPage<HomeProps> = ({ headBanners, adBanners, articleList, specialBanners }) => {
  const { t } = useTranslation(['common']);
  const { lang } = useContext(LanguageContext);
  const { isMobile } = useContext(StoreContext);

  return (
    <>
      <Head>
        <title>{getSeoTitle()}</title>
        <meta name="description" content={t('metaHome')} />
      </Head>

      <>
        <Section className="section-bg">
          {headBanners?.results && (
            <Container className="mobile-no-padding">
              <Carousel
                type="banner"
                settings={{
                  prevArrow: (
                    <Button>
                      <Icon component={RowLeft} />
                    </Button>
                  ),
                  nextArrow: (
                    <Button
                      buttonClass={{
                        ...(headBanners.count && {
                          left: `${headBanners.count * 30 + 110 + 25}px`,
                        }),
                      }}
                    >
                      <Icon component={RowRight} />
                    </Button>
                  ),
                }}
              >
                {headBanners.results.map((el) => {
                  let i18n;
                  try {
                    i18n = JSON.parse(el.i18n[lang] as string);
                  } catch (e) {
                    i18n = { title: el.i18n[lang], content: el.i18n[lang], link: el.i18n[lang] };
                  }

                  return (
                    <ItemBanner
                      key={el.id}
                      background={el.image}
                      href={i18n.link}
                      title={i18n.title}
                      description={i18n.content}
                    />
                  );
                })}
              </Carousel>
            </Container>
          )}

          <Container>
            <NavigationSettings specialBanners={specialBanners} />
          </Container>
        </Section>

        <Responsive>
          <>
            <Section bgImage="/technology.jpg" className={cn('section-bg__image pt-80', 'pb-80')}>
              {!isMobile && adBanners?.results && (
                <Container>
                  <Carousel
                    type="components-links"
                    settings={{
                      autoplay: false,
                      prevArrow: (
                        <Button>
                          <Icon component={PrevArrow} />
                        </Button>
                      ),
                      nextArrow: (
                        <Button>
                          <Icon component={NextArrow} />
                        </Button>
                      ),
                    }}
                  >
                    <ItemComponents>
                      <SliderBox data={adBanners.results.slice(0, 4)} />
                    </ItemComponents>
                    {adBanners.results.length > 4 && (
                      <ItemComponents>
                        <SliderBox data={adBanners.results.slice(4, 8)} />
                      </ItemComponents>
                    )}
                    {adBanners.results.length > 8 && (
                      <ItemComponents>
                        <SliderBox data={adBanners.results.slice(8, 12)} />
                      </ItemComponents>
                    )}
                  </Carousel>
                </Container>
              )}
            </Section>
            <Section className="section-bg">
              <Wrapper articleList={articleList} />
            </Section>
          </>
        </Responsive>
      </>
    </>
  );
};

Home.getInitialProps = async ({ query }) => {
  const queryClient = new QueryClient(defaultOptions);

  await queryClient.prefetchQuery(
    ['headBanners', { type: BannerType.HEAD_BANNER }],
    (props) => banner.getList(props, { 'Accept-Language': query?.lang }),
    {
      cacheTime,
    },
  );

  await queryClient.prefetchQuery(
    ['adBanners', { type: BannerType.ADVERTISING_BANNER }],
    (props) => banner.getList(props, { 'Accept-Language': query?.lang }),
    {
      cacheTime,
    },
  );

  await queryClient.prefetchQuery(
    ['specialBanners', { type: BannerType.SPECIAL_PAGE_BANNER }],
    (props) => banner.getList(props, { 'Accept-Language': query?.lang }),
    {
      cacheTime,
    },
  );

  await queryClient.prefetchQuery(
    [
      'articleList',
      {
        ...defaultFilters,
        page_size: 18,
        tags: [
          ArticleTags.NEWS,
          ArticleTags.ANNOUNCES,
          ArticleTags.WARNINGS,
          ArticleTags.PROMOTIONS,
        ],
      },
    ],
    (props) => articles.getList(props, { 'Accept-Language': query?.lang }),
    { cacheTime },
  );

  return getQueries(queryClient) as HomeProps;
};

export default withStore()(Home);
